import React from "react";
import {graphql, useStaticQuery} from "gatsby";

export const AppointmentWidgets: React.FC = () => {

    const data = useStaticQuery(graphql`
        query {
            allStrapiAppointmentWidget {
                nodes {
                    strapi_id
                    html
                    admin_user {
                        firstname
                        lastname
                    }
                }
            }   
        }
    `)

    return <>

        <div className="container pb-60">
            <div className="row" >
                <div className="col-12" >
                    { data.allStrapiAppointmentWidget.nodes.map(widget => (
                        <div key={widget.strapi_id} dangerouslySetInnerHTML={{__html: widget.html}}></div>)
                    )}
                </div>
            </div>
        </div>
    </>;
}