import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import Layout from "@components/layout";
import CommonPageHeader from "@components/common/CommonPageHeader/CommonPageHeader";
import {AppointmentArea} from "@components/appointment/AppointmentArea";
import {AppointmentWidgets} from "@components/appointment/AppointmentWidgets";

const AppointmentPage: React.FC<PageProps> = () => {
    return (
        <Layout>
            <CommonPageHeader title={"Umów wizytę"} subtitle={""} />
            <AppointmentArea />
            <AppointmentWidgets />
        </Layout>
    )
}

export default AppointmentPage;

export const Head: HeadFC = () => <title>Umów wizytę - Fizjoproject Kiekrz</title>
