import React from 'react';

export const AppointmentArea: React.FC = () => {

    return <>
        <section className="appointment-area pt-30">
            <div className="container">
                <div className="row" >
                    <div className="col-12" >
                        <div className="pt-55 mb-30" >
                            <div className="about-title mb-20" >
                                <h1>Umów się z nami na wizytę.</h1></div>
                            <div className="about-text mb-50" >
                                <p>
                                    Poniżej możesz umówić wizytę online, ale nie martw się jeśli nie będzie dla Ciebie odpowiedniego terminu. Zadzwoń do nas, a wspólnie uda nam się ustalić dogodny termin ;)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
}